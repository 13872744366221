import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { inject } from '@angular/core';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

// Flag to track refresh token request
let isRefreshing = false;
// Subject to queue requests while refreshing token
const tokenSubject = new BehaviorSubject<string | null>(null);

// Add paths that should be excluded from authentication
const excludedPaths = [
  '/auth/login', 
  '/auth/register',
  '/auth/reset-password'
];

export const AuthInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const modal = inject(NzModalService);

  // Check if the request URL matches any excluded paths
  const isExcluded = excludedPaths.some(path => req.url.includes(path));
  if (isExcluded) {
    return next(req);
  }

  if (authService.isAuthenticated()) {
    const token = localStorage.getItem('token');
    if (token) {
      req = addTokenToRequest(req, token);
      return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Check for token expiration error code E_0021
            if (error.error?.error?.code === 'E_0021') {
              return handleTokenExpiration(req, next, authService, router, modal);
            } else {
              // Other 401 errors - clear auth and redirect to login
              return handleAuthError(authService, router, modal, error);
            }
          }
          return throwError(() => error);
        })
      );
    }
  }

  // For non-authenticated requests to protected endpoints, redirect to login
  if (!isExcluded) {
    authService.logout();
    router.navigate(['/login']);
    return EMPTY;
  }

  return next(req);
};

function addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
  return request.clone({
    headers: request.headers.set('Authorization', `Bearer ${token}`),
  });
}

function handleTokenExpiration(
  request: HttpRequest<any>,
  next: HttpHandlerFn,
  authService: AuthService,
  router: Router,
  modal: NzModalService
): Observable<HttpEvent<any>> {
  if (!isRefreshing) {
    isRefreshing = true;
    tokenSubject.next(null);

    return authService.refreshToken().pipe(
      switchMap(response => {
        isRefreshing = false;
        tokenSubject.next(response.data.accessToken);
        return next(addTokenToRequest(request, response.data.accessToken));
      }),
      catchError(error => {
        isRefreshing = false;
        return handleAuthError(authService, router, modal, error);
      })
    );
  }

  // Wait for new token
  return tokenSubject.pipe(
    filter(token => token !== null),
    take(1),
    switchMap(token => next(addTokenToRequest(request, token!)))
  );
}

function handleAuthError(
  authService: AuthService,
  router: Router,
  modal: NzModalService,
  error: any
): Observable<never> {
  authService.logout();

  modal.error({
    nzTitle: 'Session Expired',
    nzContent: 'Your session has expired. Please log in again.',
    nzOnOk: () => {
      router.navigate(['/login']);
    },
  });

  return throwError(() => error);
}
