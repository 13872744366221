import { Component, OnInit } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule, NzTableSortFn } from 'ng-zorro-antd/table';
import { RouterLink } from '@angular/router';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormsModule,
} from '@angular/forms';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { StaffService } from '../../../core/services/staff/staff.service';
import { CompanyService } from '../../../core/services/company/company.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { finalize } from 'rxjs';

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

interface StaffData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  companies: {
    id: string;
    name: string;
  }[];
}

@Component({
  selector: 'app-staff-list',
  standalone: true,
  imports: [
    NgForOf,
    NzButtonModule,
    NzTableModule,
    RouterLink,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzIconDirective,
    NzSelectModule,
    NgIf,
    FormsModule,
  ],
  templateUrl: './staff-list.component.html',
  styleUrl: './staff-list.component.scss',
})
export class StaffListComponent implements OnInit {
  isVisible = false;
  form: FormGroup;
  loading = false;
  companies: { companyId: string; companyName: string }[] = [];
  isEditing = false;
  editingId: string | null = null;
  selectedCompanyId: string | null = null;
  filteredListOfData: StaffData[] = [];
  submitLoading = false;
  deleteLoading: string | null = null;

  listOfColumn: ListOfColumn[] = [
    {
      title: 'Name',
      compare: (a: StaffData, b: StaffData) =>
        `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
      priority: false,
      showSort: true,
      align: null,
      width: null,
    },
    {
      title: 'Email',
      compare: (a: StaffData, b: StaffData) => a.email.localeCompare(b.email),
      priority: false,
      showSort: true,
      align: null,
      width: null,
    },
    {
      title: 'Role',
      compare: (a: StaffData, b: StaffData) => a.role.localeCompare(b.role),
      priority: false,
      showSort: true,
      align: null,
      width: null,
    },
    {
      title: 'Companies',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: null,
    },
    {
      title: 'Actions',
      showSort: false,
      priority: false,
      compare: null,
      align: 'center',
      width: '250px',
    },
  ];

  listOfData: StaffData[] = [];

  constructor(
    private fb: FormBuilder,
    private staffService: StaffService,
    private companyService: CompanyService,
    private notification: NotificationService,
    private modal: NzModalService
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      companies: [[], Validators.required],
    });
  }

  ngOnInit() {
    this.fetchStaffData();
    this.fetchCompanies();
  }

  private fetchStaffData() {
    this.loading = true;
    this.staffService
      .getStaffList()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: response => {
          this.listOfData = response.data;
          if (this.companies.length > 0 && !this.selectedCompanyId) {
            this.selectedCompanyId = this.companies[0].companyId;
          }
          this.filterStaffByCompany();
        },
        error: e => {
          this.notification.apiError(e);
        },
      });
  }

  private fetchCompanies() {
    this.companyService.companyList().subscribe({
      next: response => {
        this.companies = response.data;
        if (this.companies.length > 0 && !this.selectedCompanyId) {
          this.selectedCompanyId = this.companies[0].companyId;
          this.filterStaffByCompany();
        }
      },
      error: e => {
        this.notification.apiError(e);
      },
    });
  }

  onCompanyChange(companyId: string) {
    this.selectedCompanyId = companyId;
    this.filterStaffByCompany();
  }

  private filterStaffByCompany() {
    if (!this.selectedCompanyId) {
      this.filteredListOfData = this.listOfData;
    } else {
      this.filteredListOfData = this.listOfData.filter(staff =>
        staff.companies.some(company => company.id === this.selectedCompanyId)
      );
    }
  }

  onCreate() {
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
    this.isEditing = false;
    this.editingId = null;
    this.form.reset();
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitLoading = true;

      if (this.isEditing && this.editingId) {
        this.staffService
          .updateStaff(this.form.value.role, this.form.value.email)
          .pipe(finalize(() => (this.submitLoading = false)))
          .subscribe({
            next: () => {
              this.notification.success('Update Staff Successfully', '');
              this.handleCancel();
              this.fetchStaffData();
            },
            error: e => {
              this.notification.apiError(e);
            },
          });
      } else {
        this.staffService
          .createStaff({
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            email: this.form.value.email,
            role: this.form.value.role,
            companies: this.form.value.companies,
          })
          .pipe(finalize(() => (this.submitLoading = false)))
          .subscribe({
            next: () => {
              this.notification.success('Create Staff Successfully', '');
              this.handleCancel();
              this.fetchStaffData();
            },
            error: e => {
              this.notification.apiError(e);
            },
          });
      }
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  onEdit(id: string) {
    const staff = this.listOfData.find(x => x.id === id);
    if (staff) {
      this.isEditing = true;
      this.editingId = id;
      this.form.patchValue({
        firstName: staff.firstName,
        lastName: staff.lastName,
        email: staff.email,
        role: staff.role,
        companies: staff.companies.map(x => x.id),
      });
      this.isVisible = true;
    }
  }

  onDelete(id: string) {
    const staff = this.listOfData.find(x => x.id === id);
    if (staff) {
      this.modal.confirm({
        nzTitle: 'Are you sure you want to delete this staff?',
        nzContent: 'This action cannot be undone',
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzOnOk: () => this.deleteStaff(staff.role, staff.email),
        nzCancelText: 'No',
      });
    }
  }

  private deleteStaff(role: string, email: string) {
    const staff = this.listOfData.find(x => x.role === role && x.email === email);
    if (staff) {
      this.deleteLoading = staff.id;
      this.staffService
        .deleteStaff(role, email)
        .pipe(finalize(() => (this.deleteLoading = null)))
        .subscribe({
          next: () => {
            this.notification.success('Delete Staff Successfully', '');
            this.fetchStaffData();
          },
          error: e => {
            this.notification.apiError(e);
          },
        });
    }
  }
}
