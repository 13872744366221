<div class="staff-page">
  <div class="staff-page__content">
    <div class="d-flex d-jus-between">
      <div class="app-text-header">Staff</div>
      <div>
        <button (click)="onCreate()" class="btn-system" nz-button nzSize="large">
          <span nz-icon nzTheme="outline" nzType="plus"></span>Add Staff
        </button>
      </div>
    </div>

    <div class="mb-16">
      <nz-form-item>
        <nz-form-label>Filter by Company</nz-form-label>
        <nz-form-control>
          <nz-select 
            [(ngModel)]="selectedCompanyId" 
            (ngModelChange)="onCompanyChange($event)"
            style="width: 200px;"
          >
            @for (company of companies; track company.companyId) {
              <nz-option 
                [nzValue]="company.companyId" 
                [nzLabel]="company.companyName"
              ></nz-option>
            }
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-table #sortTable [nzData]="filteredListOfData" [nzLoading]="loading" nzTableLayout="fixed">
      <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumn"
            [nzAlign]="column.align"
            [nzShowSort]="column.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width"
          >
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of sortTable.data">
          <td>{{ data.firstName }} {{ data.lastName }}</td>
          <td>{{ data.email }}</td>
          <td>{{ data.role }}</td>
          <td>{{ data.companies[0]?.name }}</td>
          <td nzAlign="center">
            <div class="d-flex d-jus-center" style="gap: 24px">
              <button (click)="onEdit(data.id)" nz-button>
                <span nz-icon nzTheme="outline" nzType="edit"></span>Edit
              </button>
              <button 
                (click)="onDelete(data.id)" 
                nz-button 
                nzDanger
                [nzLoading]="deleteLoading === data.id"
              >
                <span nz-icon nzTheme="outline" nzType="delete"></span>Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
>
  <ng-template #modalTitle>{{ isEditing ? 'Edit Staff Role' : 'Add Staff' }}</ng-template>

  <ng-template #modalContent>
    <form [formGroup]="form" nz-form>
      <nz-form-item *ngIf="!isEditing">
        <nz-form-label nzRequired nzSpan="6">First Name</nz-form-label>
        <nz-form-control nzErrorTip="Please input first name" nzSpan="18">
          <input formControlName="firstName" nz-input />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!isEditing">
        <nz-form-label nzRequired nzSpan="6">Last Name</nz-form-label>
        <nz-form-control nzErrorTip="Please input last name" nzSpan="18">
          <input formControlName="lastName" nz-input />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!isEditing">
        <nz-form-label nzRequired nzSpan="6">Email</nz-form-label>
        <nz-form-control nzErrorTip="Please input valid email" nzSpan="18">
          <input formControlName="email" nz-input type="email" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzSpan="6">Role</nz-form-label>
        <nz-form-control nzErrorTip="Please select role" nzSpan="18">
          <nz-select formControlName="role">
            <nz-option nzValue="HR" nzLabel="HR"></nz-option>
            <nz-option nzValue="INTERVIEWER" nzLabel="INTERVIEWER"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!isEditing">
        <nz-form-label nzRequired nzSpan="6">Companies</nz-form-label>
        <nz-form-control nzErrorTip="Please select companies" nzSpan="18">
          <nz-select formControlName="companies" nzMode="multiple">
            @for (company of companies; track company.companyId) {
              <nz-option [nzValue]="company.companyId" [nzLabel]="company.companyName"></nz-option>
            }
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button 
      class="btn-system" 
      nz-button 
      nzType="primary" 
      (click)="onSubmit()"
      [nzLoading]="submitLoading"
    >
      {{ isEditing ? 'Update' : 'Submit' }}
    </button>
  </ng-template>
</nz-modal>
