import { Component, OnInit } from '@angular/core';
import { NgForOf, TitleCasePipe, NgIf } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule, NzTableSortFn } from 'ng-zorro-antd/table';
import { RouterLink } from '@angular/router';
import { JobService } from '../../../core/services/job/job.service';
import { NzTagComponent } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { finalize } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { CompanyService } from '../../../core/services/company/company.service';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

interface DataItem {
  id: string;
  jobTitle: string;
  status: string;
  createdDate: string;
  numberOfApplicant: number;
}

@Component({
  selector: 'app-job-list',
  standalone: true,
  imports: [
    NgForOf,
    NzButtonModule,
    NzTableModule,
    RouterLink,
    NzTagComponent,
    TitleCasePipe,
    NzIconModule,
    NzModalModule,
    NzUploadModule,
    NgIf,
    NzSelectModule,
    FormsModule,
  ],
  templateUrl: './job-list.component.html',
  styleUrl: './job-list.component.scss',
})
export class JobListComponent implements OnInit {
  listOfColumn: ListOfColumn[] = [
    {
      title: 'No',
      compare: null,
      priority: false,
      showSort: false,
      align: null,
      width: '100px',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.jobTitle.localeCompare(b.jobTitle),
      priority: false,
      showSort: false,
      align: null,
      width: '350px',
    },
    {
      title: 'Applicants',
      compare: null,
      priority: false,
      showSort: false,
      align: 'center',
      width: '150px',
    },
    {
      title: 'Status',
      compare: null,
      priority: false,
      showSort: false,
      align: 'center',
      width: '150px',
    },

    {
      title: '',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: null,
    },
  ];
  listOfData: DataItem[] = [];
  loading = false;
  fileList: NzUploadFile[] = [];
  isImportModalVisible = false;
  companies: { companyId: string; companyName: string }[] = [];
  selectedCompanyId: string = '';

  constructor(
    private jobService: JobService,
    private notification: NotificationService,
    private modal: NzModalService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.fetchJobList();
    this.fetchCompanies();
  }

  private fetchCompanies() {
    this.companyService.companyList().subscribe({
      next: (response) => {
        this.companies = response.data.map(company => ({
          companyId: company.companyId,
          companyName: company.companyName
        }));
      },
      error: (e) => {
        this.notification.apiError(e);
      }
    });
  }

  onActivate(id: string) {
    this.jobService.jobActivate(id).subscribe({
      next: () => {
        this.notification.success('Success', 'Your Job is activated.');
        this.fetchJobList();
      },
      error: e => {
        this.notification.error(`Error ${e.error.errorMsg}`, '');
      },
    });
  }

  private fetchJobList() {
    this.loading = true;
    this.jobService
      .jobList(1, 100)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.listOfData = response.data.data;
        },
      });
  }

  showImportModal(): void {
    this.isImportModalVisible = true;
    this.selectedCompanyId = '';
  }

  handleImportCancel(): void {
    this.isImportModalVisible = false;
    this.fileList = [];
  }

  downloadTemplate(): void {
    const link = document.createElement('a');
    link.setAttribute('href', 'assets/files/import-template.xlsx');
    link.setAttribute('download', 'job-import-template.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      this.notification.error('Error', 'You can only upload Excel files!');
      return false;
    }
    this.fileList = [file];
    return false;
  };

  onImport(): void {
    if (this.fileList.length === 0) {
      this.notification.error('Error', 'Please select a file to import');
      return;
    }

    if (!this.selectedCompanyId) {
      this.notification.error('Error', 'Please select a company');
      return;
    }

    const file = this.fileList[0] as any;
    const data = {
      companyId: this.selectedCompanyId,
    };

    this.loading = true;
    this.jobService.importJobs(file, data)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.notification.success('Success', 'Jobs imported successfully');
          this.fileList = [];
          this.isImportModalVisible = false;
          this.fetchJobList();
        },
        error: (e) => {
          this.notification.apiError(e);
        }
      });
  }
}
