import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseResponse } from '../base/base.model';
import {
  CreateStaffRequest,
  StaffData,
  StaffListRawResponse,
  StaffListResponse,
  UpdateStaffRequest,
} from './staff.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  private apiUrl = `${environment.apiUrl}/staff`;

  constructor(private http: HttpClient) {}

  getStaffList(): Observable<StaffListResponse> {
    const url = `${environment.apiUrl}/companies/staff`;
    return this.http.get<StaffListRawResponse>(url).pipe(
      map(response => ({
        ...response,
        data: response.data.flatMap(company =>
          company.companyStaff.map(staff => ({
            id: `${company.companyId}-${staff.email}`, // Generate unique ID by combining company ID and email
            firstName: staff.staffName.split(' ')[0],
            lastName: staff.staffName.split(' ')[1] || '',
            email: staff.email,
            role: staff.role,
            companies: [
              {
                id: company.companyId,
                name: company.companyName,
              },
            ],
            createdAt: new Date().toISOString(), // Note: Add if needed from response
            updatedAt: new Date().toISOString(), // Note: Add if needed from response
          }))
        ),
      }))
    );
  }

  createStaff(request: CreateStaffRequest): Observable<BaseResponse<StaffData>> {
    // Transform request to match API format
    const apiRequest = {
      firstname: request.firstName,
      lastname: request.lastName,
      companyIds: request.companies,
      role: request.role,
      email: request.email,
    };

    const url = `${environment.apiUrl}/companies/staff`;
    return this.http.post<BaseResponse<StaffData>>(url, apiRequest);
  }

  updateStaff(role: string, email: string): Observable<BaseResponse<StaffData>> {
    const url = `${environment.apiUrl}/companies/staff`;

    return this.http.put<BaseResponse<StaffData>>(url, {
      role,
      email,
    });
  }

  deleteStaff(role: string, email: string): Observable<BaseResponse<void>> {
    const url = `${environment.apiUrl}/companies/staff/remove`;

    return this.http.post<BaseResponse<void>>(url, {
      role,
      email,
    });
  }
}
