<div class="job-list-page">
  <div class="job-list-page__content">
    <div class="d-flex d-jus-between">
      <div class="app-text-header">Career Listings</div>
      <div style="display: flex; gap: 16px">
        <button nz-button nzSize="large" (click)="showImportModal()">
          <span nz-icon nzType="import"></span>Import
        </button>

        <button class="btn-system" nz-button nzSize="large" routerLink="create">
          <span nz-icon nzTheme="outline" nzType="plus"></span>New
        </button>
      </div>
    </div>
    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="loading" nzTableLayout="fixed">
      <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumn"
            [nzAlign]="column.align"
            [nzShowSort]="column?.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width"
          >
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of sortTable.data; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ data.jobTitle }}</td>
          <td nzAlign="center">{{ data.numberOfApplicant }}</td>
          <td nzAlign="center">
            @switch (data.status) {
              @case ('ACTIVE') {
                <nz-tag class="btn-system">{{ data.status | titlecase }}</nz-tag>
              }
              @default {
                <nz-tag class="btn-system--normal">{{ data.status | titlecase }}</nz-tag>
              }
            }
          </td>
          <td nzAlign="center">
            <div class="d-flex d-jus-center" style="gap: 24px">
              <button [routerLink]="['/', 'ranking', data.id]" nz-button>
                <span nz-icon nzTheme="outline" nzType="ordered-list"></span>Ranking
              </button>
              <button [routerLink]="['edit', data.id]" nz-button>
                <span nz-icon nzTheme="outline" nzType="file-text"></span>
                Detail
              </button>
              <button (click)="onActivate(data.id)" [disabled]="data.status === 'ACTIVE'" nz-button>
                <span nz-icon nzTheme="outline" nzType="file-done"></span>Activate
              </button>
              <button disabled nz-button>
                <span nz-icon nzTheme="outline" nzType="delete"></span>Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isImportModalVisible"
  [nzTitle]="'Import Jobs'"
  (nzOnCancel)="handleImportCancel()"
  [nzFooter]="null"
>
  <ng-container *nzModalContent>
    <div class="import-modal-content">
      <div class="mb-24">
        <h4>Steps to import jobs:</h4>
        <ol>
          <li>Select a company</li>
          <li>Download the template file</li>
          <li>Fill in the job details in the template</li>
          <li>if you want add more job, you can add new sheet in the excel file</li>
          <li>Upload the completed file</li>
          <li>Click "Import Jobs" button</li>
        </ol>
      </div>

      <div class="mb-24">
        <label class="company-select-label">Select Company</label>
        <nz-select
          class="company-select"
          [(ngModel)]="selectedCompanyId"
          nzPlaceHolder="Select a company"
        >
          @for (company of companies; track company.companyId) {
            <nz-option [nzValue]="company.companyId" [nzLabel]="company.companyName"></nz-option>
          }
        </nz-select>
      </div>

      <div class="d-flex d-jus-between mb-24">
        <button nz-button (click)="downloadTemplate()" [disabled]="!selectedCompanyId">
          <span nz-icon nzType="download"></span>Download Template
        </button>

        <nz-upload [nzBeforeUpload]="beforeUpload" [nzShowUploadList]="false" [nzAccept]="'.xlsx'">
          <button nz-button [disabled]="!selectedCompanyId">
            <span nz-icon nzType="upload"></span>Select File
          </button>
        </nz-upload>
      </div>

      <div *ngIf="fileList.length > 0" class="d-flex d-jus-between">
        <span>Selected file: {{ fileList[0].name }}</span>
        <button nz-button nzType="primary" (click)="onImport()" [nzLoading]="loading">
          Import Jobs
        </button>
      </div>
    </div>
  </ng-container>
</nz-modal>
